<template>
  <div>
    <ClipLoader v-if="pending" />
    <template v-else-if="!pending && $route.params.categorySlug?.toString()">
      <EmptyItemsBanner v-if="!category" />
      <CategoryView
        v-else-if="category?.subcategory?.length"
        :category="category"
        :breadcrumbs="breadcrumbs"
        :back-button-slug="parentSlug"
        :oci-mode="ociMode"
      />
      <ProductsView
        v-else
        :title="category?.title"
        :breadcrumbs="breadcrumbs || []"
        :category-id="parsedCategoryId.toString()"
        :oci-mode="ociMode"
        :is-show-price="isShowPrice"
        :client-id="clientId"
        :back-button-slug="parentSlug"
        :products-fetch-key="productsFetchKey"
        :filters-fetch-key="filtersFetchKey"
      />
    </template>
  </div>
</template>

<script lang="ts" setup>
import CategoryView from 'components/CategoryView.vue';
import ProductsView from 'components/ProductsView.vue';
import EmptyItemsBanner from 'components/EmptyItemsBanner.vue';
import BasesService from 'services/basesManager.service';
import { ELayout } from 'enums/layouts.enum';
import { PimCatalogService } from 'services/api/pimCatalogApi.service';
import Notificator from 'shared/services/notificator.service';
import { useShowPriceClient } from 'composables/useShowPriceHelper';
import { CategoryUtil } from 'utils/category.util';
import { ICategory } from 'shared/models/category.model';
import { useCanonicalLink } from 'composables/useCanonicalLink';
import { useBasis } from 'composables/useBasis';
import { EPageMiddleware } from 'enums/pageMiddleware.enum';
import { EFetchKey } from 'enums/fetchKey.enum';
import { clientSentry } from 'shared/utils/sentry/clientSentry.util';
import { useUserStore } from 'store/user.store';

definePageMeta({
  layout: ELayout.Marketplace,
  middleware: [EPageMiddleware.UpdateBasis],
});

useCanonicalLink();

const props = defineProps<{
  ociMode?: boolean;
  clientId?: number;
}>();

const basesManager = inject<BasesService>(BasesService.getServiceName());
const notificator = process.client ? inject<Notificator>(Notificator.getServiceName()) : undefined;

const userStore = useUserStore();
const route = useRoute();
const categorySlug = route.params?.categorySlug?.toString() ?? '';
const parsedCategorySlug = categorySlug?.split?.('-');
const parsedCategoryId = parsedCategorySlug?.[parsedCategorySlug.length - 1] || 'category';
const isShowPrice = useShowPriceClient(props.ociMode);
const { authBasisId, unAuthRegionFiasId } = useBasis();

const { data, refresh, pending } = await useLazyAsyncData(
  `${EFetchKey.Category}-${categorySlug}`,
  async () => {
    try {
      return await PimCatalogService.getCategoryById(
        parsedCategoryId as string,
        3,
        unAuthRegionFiasId.value,
        props.ociMode ? undefined : props.ociMode,
        props.ociMode,
        props.ociMode,
        props.clientId,
        authBasisId.value,
        undefined,
        props.ociMode,
      );
    } catch (error) {
      clientSentry.captureComponentException(error, 'pages/[categorySlug]/index.vue');
    }

    return {
      category: undefined,
      breadcrumbs: undefined,
      products: undefined,
    };
  },
  {
    watch: [() => userStore.isUserLoggedIn],
  },
);

const category = computed<ICategory | undefined>(
  () => data.value?.category && CategoryUtil.correctCategory(data.value.category, props.ociMode),
);

const breadcrumbs = computed(() => CategoryUtil.correctBreadcrumbs(data.value?.breadcrumbs || [], props.ociMode));

const parentSlug = computed(() => {
  if (!props.ociMode) {
    return '';
  }

  const slug = category.value?.parents?.length ? category.value.parents[category.value.parents.length - 1].slug : '/categories';

  return `/oci${slug}`;
});

const productsFetchKey = computed(() => `${EFetchKey.Products}-${categorySlug}`);

const filtersFetchKey = computed(() => `${EFetchKey.Filters}-${categorySlug}`);

useObserveBases(refresh);

onMounted(() => {
  if (basesManager?.isConflictBasesCategory(data?.value?.category)) {
    notificator?.showNotification('Товары из данной категории не доступны для заказа на выбранный адрес доставки');
  }
});
</script>
